const Strings = {
  // Homepage strings
  HOME_TITLE: "Save time & get paid faster with Anansii",
  HOME_CTA_LABEL: "Want to learn more?",
  HOME_CTA_BTN_TXT: "Get Started",
  HOME_CONTACT_EMAIL: "mailto:hello@anansii.com",
  HOME_BENEFITS_VISUALIZE:
    "Sort & filter aging data to quickly determine which clients need a follow-up",
  HOME_BENEFITS_CONTACT:
    "Send reminders instantly, with suggestions from your Gmail contacts",
  HOME_BENEFITS_GROW:
    "Easy setup with any accounting system with zero IT effort",
  HOME_SUPPLIER_SECTION_HEADER_1: "Sell to Small Businesses?",
  HOME_SUPPLIER_SECTION_HEADER_2: "Anansii helps you stay on top of your AR",

  // Auth strings
  DASHBOARD_SIGNIN_FORM_TITLE: "Sign In to your Dashboard",
  DASHBOARD_SIGNIN_FORM_ADDITIONAL_INFO:
    "No password needed! We'll send you a magic link to sign in.",
  DASHBOARD_SIGNIN_FORM_SUBMIT_BTN_TXT: "Sign In",
  DASHBOARD_AUTH_LINK_REF: "/dashboard/auth",

  SIGNIN_LOCAL_STORAGE_KEY: "signInEmail",
  SIGNIN_AUTH_EMAIL_CONFIRM: "We sent an email to",
  SIGNIN_AUTH_EMAIL_CONFIRM_CONT:
    "Click the link in the email to sign into your account.",
  SIGNIN_BACK_TO_EMAIL_LABEL: "Wrong email address?",
  SIGNIN_BACK_TO_EMAIL_BTN_TXT: "Go Back",

  AUTH_NO_EMAIL_IN_LOCAL_STORAGE: "Please provide your email for confirmation",

  CLIENT_SIGNIN_FORM_TITLE: "Make a payment",
  CLIENT_SIGNIN_FORM_DESC:
    "Your distributor is using Anansii to make it even easier for you to pay for your invoices!",
  CLIENT_SIGNIN_REDIRECT_NOTIFICATION:
    "Sign in to access your account and make a payment",

  CUSHION_SIGNIN_FORM_TITLE: "Get instant financing for your business",
  CUSHION_SIGNIN_FORM_DESC:
    "Unlock special financing offers from our partners as you pay your invoices on-time.",
  CUSHION_SIGNIN_REDIRECT_NOTIFICATION:
    "Sign in to unlock business financing from our partners.",

  ROADBUILDER_SIGNIN_FORM_TITLE: "Sign up, onboard, & apply for terms",
  ROADBUILDER_SIGNIN_FORM_DESC:
    "Your supplier is using Anansii to make getting started simple",
  ROADBUILDER_VALIDATION_ERROR_TOAST:
    "Oops! We found a few issues with your submission. Please review, update, and try again.",
  ROADBUILDER_VALIDATION_PHONE_NUMBER_ERROR:
    "Please enter a valid phone number",
  ROADBUILDER_VALIDATION_DATE_ERROR: "Please enter a valid date",
  ROADBUILDER_VALIDATION_DATE_ERROR_FUTURE: "Date cannot be in the future",
  ROADBUILDER_VALIDATION_ADDRESS_ERROR: "Please enter a valid address",
  ROADBUILDER_VALIDATION_EMAIL_ERROR: "Please enter a valid email address",
  ROADBUILDER_VALIDATION_TERMS_ERROR:
    "Please enter your terms with the vendor. If no terms, enter COD.",

  ROADBUILDER_RESEND_INVITE_DESCRIPTION:
    "Resend the invite email to the applicant",
  ROADBUILDER_REOPEN_APPLICATION_DESCRIPTION:
    "Enables customer to edit the application & invalidates existing signatures",
  ROADBUILDER_APPROVE_APPLICATION_DESCRIPTION: "Mark application as approved",
  ROADBUILDER_ARCHIVE_APPLICATION_DESCRIPTION: "Disable access to application",
  ROADBUILDER_DOWNLOAD_APPLICATION_DESCRIPTION:
    "Download completed application",

  // Profile strings
  PROFILE_PG_TITLE: "Welcome!",
  PROFILE_PG_DESC:
    "Just a couple more steps to set up your automated assistant...",
  PROFILE_NAME_CARD_TITLE: "Hi there! What's your name?",
  PROFILE_TEAM_SELECTION_LABEL: "What team are you on?",

  ERROR_PROFILE_NO_NAME_INPUT:
    "Please enter the name you use with your clients",
  ERROR_PROFILE_TERMS_NOT_ACCEPTED:
    "Please accept our terms & conditions to continue",

  ACCT_SW_CARD_TITLE: "What accounting software do you use?",

  QUICKBOOKS_CARD_TITLE: "Connect Assistant to QuickBooks",
  QUICKBOOKS_CARD_DESC:
    "Assistant uses invoices and account info in QuickBooks to power your personalized dashboard.",

  EMAIL_SETUP_CARD_TITLE: "Welcome!",
  EMAIL_SETUP_CARD_DESC: "Next step",
  EMAIL_SETUP_CARD_INSTRUCTIONS:
    "Forward your most recent client aging file (Excel or CSV please!) to ",
  EMAIL_SETUP_CARD_INSTRUCTIONS_CONT:
    " and we'll have your dashboard set up in no time.",
  EMAIL_SETUP_MAILTO: "assistant@inbound.anansii.com",

  // Dashboard strings
  WELCOME_CARD_TITLE: "Welcome!",
  WELCOME_CARD_DESC: "Welcome to your Anansii Dashboard!",
  DASH_EMPTY_STATE_TITLE: "Check back soon!",
  DASH_EMPTY_STATE_DESC:
    "If you have not done so already, please send an email to assistant@inbound.anansii.com with your aging data attached.",
  DASH_EMPTY_STATE_DESC_2:
    "After you send the aging file, wait about 60 seconds, refresh this page.",

  // Table header & Details modal
  ACCT_TABLE_HEADER: "Clients",
  ACCT_TABLE_CURRENT_PERCENTAGE_CALLOUT: "Current% (by value)",
  ACCT_DETAILS_TERMS_LABEL: "Terms",
  ACCT_DETAILS_STATUS_LABEL: "Status",
  ACCT_DETAILS_BALANCE_LABEL: "Total Balance",
  ACCT_DETAILS_OVERDUE_LABEL: "Amount Past Due",

  // Details modal
  DETAILS_DIALOG_HEADER: "Client Details",
  DETAILS_DIALOG_LASTPAYMENT_LABEL: "Last Payment",
  DETAILS_DIALOG_CONTACTS_LABEL: "Contact Email(s)",
  DETAILS_DIALOG_SWITCHDIALOG_CTA: "Send Email",
  DETAILS_DIALOG_DISMISS_CTA: "Close",

  // Forms
  SIGNIN_EMAIL_PLACEHOLDER: "yourname@yourcompany.com",

  PROFILE_NAME_PLACEHOLDER: "e.g. Béyonce Knowles",
  PROFILE_NAME_UPDATE_BTN_TXT: "Update",
  PROFILE_NAME_SAVE_BTN_TXT: "Next",
  ACCT_SW_SUBMIT_BTN_TXT: "Next",
  EMAIL_SETUP_SUBMIT_BTN_TXT: "Continue to Dashboard",

  MSG_EMAIL_PLACEHOLDER_CONTACTS: "Type the name or email...",
  MSG_EMAIL_PLACEHOLDER_NO_CONTACTS: "Type an email...",
  MSG_TEXT_PLACEHOLDER: "Type your personalized message here...",
  GOOGLE_CONTACTS_AUTH_CTA: "Import GMail Contacts",
  GOOGLE_SHEETS_AUTH_CTA: "Import a statement",
  MSG_SEND_BTN_TXT: "Send",
  MSG_SEND_TO_LABEL: "Contact Email",
  MSG_SEND_CC_LABEL: "CC",
  MSG_LASTSENT_LABEL: "Last Message Sent",
  MSG_PASTE_SHEET_TIP:
    "Tip: Paste a Google spreadsheet link in your message to quickly insert a table.",
  MSG_ADDRESS_PREFIX: "For faster processing, please send checks to: \n",

  SHEETIMPORT_MODAL_HEADER: "Insert Table from Spreadsheet",
  SHEETIMPORT_SELECT_FIELDS_LABEL: "Select columns to insert",
  SHEETIMPORT_SELECT_DATE_RANGE_LABEL: "Select invoice date range",
  SHEETIMPORT_LATEST_LABEL: "Latest",
  SHEETIMPORT_OLDEST_LABEL: "Oldest",
  SHEETIMPORT_CANCEL_CTA: "Cancel",
  SHEETIMPORT_SUBMIT_CTA: "Import",

  CLIENT_TABLE_SEARCH_LABEL: "Search accounts:",
  CLIENT_TABLE_PLACEHOLDER: "Enter Name or ID",

  NO_PREV_MSGS_EMPTY_TIMESTAMP: "--",

  // Notification messages
  QUICKBOOKS_CONNECT_SUCCESS: "QuickBooks connected successfully!",
  MESSAGE_SENT_SUCCESS: "Message Sent!",
  MESSAGE_PREVIEW_SENT_SUCCESS:
    "A test message has been sent to your email address",
  MESSAGE_SENT_FAILURE:
    "Sorry, there was an error sending your message. Please try again.",
  GOOGLE_CONNECT_SUCCESS_CONTACTS:
    "Success! You can now select from your contacts when sending messages.",
  GOOGLE_CONNECT_SUCCESS_SHEETS:
    "Success! You can now insert content from Google Sheets just by pasting the link into your message.",
  GOOGLE_CONNECT_TYPE_SHEETS: "sheets",
  GOOGLE_CONNECT_TYPE_CONTACTS: "contacts",
  GOOGLE_CONNECT_TYPE_DISTRICT_SHEETS: "district_sheets",

  // Messages Modal
  MESSAGE_MODAL_PAYPORTAL_BETA_NOTIFICATION:
    "Messages sent to this client will include a link to the Anansii Pay Portal",
  AUTOCOLLECT_SWITCH_LABEL:
    "Automatically collect & process checks from this client",
  AUTOCOLLECT_NOT_ENABLED:
    "Only available on Premium tier. To enable, contact your administrator",
  AUTOCOLLECT_IS_ON:
    "Send message to share lockbox address. Anansii will take care of the rest!",
  AUTOCOLLECT_IS_OFF:
    "Turn on to have Anansii collect & deposit checks securely & automatically",

  // Admin / Shared Modes
  ADMIN_ALL_SELECTION_VALUE: "All sales reps for ",
  ADMIN_DISTRICT_SELECTION_VALUE: "All sales reps in my district",
  ADMIN_MODE_INDICATOR_ON: "Admin Mode is ON",
  ADMIN_MODE_INDICATOR_OFF: "Admin Mode is OFF. Showing only your AR.",
  ADMIN_SHARED_SELECTOR_LABEL: "Whose AR would you like to view?",
  ADMIN_CUSTOMER_SELECTOR_LABEL: "Which customer?",
  ADMIN_DISTRICTMGR_SELECTOR_LABEL: "District Manager",
  ADMIN_DISTRICTMGR_OPTION_NOT_APPLICABLE: "N/a",
  ADMIN_DISTRICTMGR_UPDATE_SUCCESSFUL:
    "Success! District manager updated for this sales rep!",
  ADMIN_SELECTION_SEPARATOR: "----",
  ADMIN_MODE_DISTRICT_OPTION: "District AR for:",
  ADMIN_DISTRICTMGR_AR_IMPORT: "Upload District Aging Report",
  ADMIN_DISTRICTMGR_AR_IMPORT_DETAILS:
    "Import your district aging report from Google Docs to update your dashboard",
  ADMIN_DISTRICTMGR_AR_PROCEED: "Import",
  ADMIN_DISTRICTMGR_AR_CANCEL: "Go Back",
  ADMIN_DISTRICTMGR_AR_IMPORT_SUCCESS:
    "District aging file received! Your dashboard will update automatically in 1-2 minutes.",
  ADMIN_DISTRICTMGR_AR_IMPORT_FAILURE:
    "There was an issue processing this link. Be sure to click 'File > Save to Google Sheets', copy the URL of the new spreadsheet, and try again.",
  ADMIN_DISTRICTMGR_AR_IMPORT_INVALID_LINK:
    "Invalid Google Docs link. Please copy and paste the link again.",

  SHARED_LINK_SEVERED_SUCCESS_MSG: "Success! You can no longer see AR for ",

  // Payment Screens
  PAYMENT_BANK_TRANSFER_AUTHORIZATION:
    "By proceeding, you authorize BiRite to debit the bank account specified above for any amount owed for charges arising from your purchase of products from BiRite, pursuant to BiRite's terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to BiRite with 30 (thirty) days notice.",
  PAYMENT_BANK_STORE_AUTHORIZATION:
    "If you purchase additional products periodically pursuant to BiRite's terms, you authorize BiRite to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained.",
  PAYMENT_INSTALLMENTS_AUTHORIZATION:
    "By proceeding, you authorize Anansii to debit the bank account specified above for any amount owed, pursuant to Anansii's terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Anansii with 30 (thirty) days notice.",
  PAYMENT_INSTALLMENTS_STORE_AUTHORIZATION:
    "If you use additional services periodically pursuant to Anansii's terms, you authorize Anansii to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained.",
  PAYMENT_CARD_SURCHARGE_WARNING_SUFFIX:
    "% fee for card payments. Avoid the fee, pay with ACH.",
  AUTOPAY_DUEDATE_DESCRIPTION: "Invoices will be paid on their due dates",
  AUTOPAY_WEEKLY_DESCRIPTION: "Invoices will be paid weekly on",
  AUTOPAY_TOOLTIP_UNAVAILABLE_ACH_ONLY:
    "Autopay is only available for ACH at this time",
  AUTOPAY_TOOLTIP_UNAVAILABLE_ACCOUNT_INELIGIBLE:
    "Your payment terms are not eligible for autopay at this time",
  AUTOPAY_NOTIFICATION_START:
    "Great! Autopay will start after your next payment.",
  AUTOPAY_NOTIFICATION_STOP:
    "Please complete a bank transfer payment to turn off autopay.",
  SUCCESS_PAGE_HEADER_CARD: "Success!",
  SUCCESS_PAGE_HEADER_ACH: "Success!",
  SUCCESS_PAGE_HEADER_CREDITS: "Success!",
  SUCCESS_PAGE_HEADER_INSTALLMENTS: "Success!",
  ARCHIVE_PAYMENT_METHOD:
    "Once confirmed, this payment method will be removed from the list. Payments scheduled with this payment method will be cancelled. Would you like to continue?",
  ARCHIVE_AUTOPAYMENT_METHOD:
    "Once confirmed, this payment method will be removed from the list and autopay will be turned off. Payments scheduled with this payment method will be cancelled. Would you like to continue?",

  // Customer Portal Sidebar Tab Keys
  PAY_TAB_KEY: "make-payment",
  ORDER_TAB_KEY: "order",
  HISTORY_TAB_KEY: "history",
  ACCOUNT_TAB_KEY: "account",
  HISTORY_INVOICE_KEY: "INVOICE",
  HISTORY_PAYMENT_KEY: "PAYMENT",
  HISTORY_CREDITMEMO_KEY: "CREDIT",
  HISTORY_FINCHARGE_KEY: "FINANCE_CHARGE",
  HISTORY_ADJUSTMENT_KEY: "ADJUSTMENT",

  // Assistant Sidebar Tab Keys
  DASHBOARD_TAB_KEY: "dashboard",
  PROFILE_TAB_KEY: "profile",
  REVIEW_QUEUE_TAB_KEY: "payment-exceptions",
  CREDIT_APPLICATIONS_TAB_KEY: "credit-applications",

  // Credit Application error messages
  ERROR_CREDIT_APPLICATION_INVITEES_ONE_OR_MORE:
    "Please specify at least one email valid address to send an application invite to",
  ERROR_CREDIT_APPLICATION_DSR_REQUIRED:
    "Please specify the sales representative responsible for this application",

  // Charge Screen
  CHARGE_REQUEST_SUCCESSFUL:
    "Payment request successful! We will notify you when funds are on their way.",

  // Error messages
  ERROR_ENTER_PROFILE_NAME: "Please enter your name!",
  ERROR_ACCEPT_TERMS: "Please accept our terms & conditions to continue",
  ERROR_PROFILE_PG_SUBMIT: "Oops! Something went wrong. Please try again.",
  ERROR_SEND_AUTH_EMAIL:
    "Sorry, there was an issue sending your the magic link. Please try again",
  ERROR_RECIPIENT_EMAIL_EMPTY: "Please enter an email address to send to!",
  ERROR_RECIPIENT_EMAIL_CHARGE_EMPTY:
    "Please enter an email address to send payment notifications to!",
  ERROR_RECIPIENT_EMAIL_ENROLL_EMPTY:
    "Please enter your client's email address to continue",
  ERROR_SIGNIN_EMAIL_INVALID:
    "Oops! This email doesn't look quite right. Please enter a valid email and try again.",
  ERROR_INVALID_EMAIL:
    "Please check to make sure that all recipient email addresses are valid!",
  ERROR_INVALID_SIGNIN_DOMAIN:
    "You must sign in with your corporate account to continue.",
  ERROR_NO_SELF_SEND:
    "Include only your email address in the 'Send to' field to receive a preview of your message",
  ERROR_MSG_EMPTY:
    "Please enter a message to your client in the Message Body field.",
  ERROR_MSG_NOT_SENT:
    "There was an issue sending your message. Please try again.",
  ERROR_CLOSED_BETA:
    "Thank you for your interest! Anansii is in a closed beta right now. If you'd like to learn more, please email hello[at]anansii[dot]com. If you've simply mistyped your email address, please try again.",
  ERROR_SHEETIMPORT_DATES_MISORDERED:
    "Oops! Please check the dates you selected. They seem to be out of order.",
  ERROR_SHEETIMPORT_REQUEST:
    "Oops! There was error importing your file. Tip: If this is an uploaded Excel file, try File > Save as Google Sheet first!",
  ERROR_CARD_PAYMENT_UNSUCCESSFUL:
    "There was an error processing your payment. Our support team is on the case and will reach out to you soon.",
  ERROR_BANK_PAYMENT_UNSUCCESSFUL:
    "There was an error processing your payment. Our support team is on the case and will reach out to you soon.",
  ERROR_CREDITS_PAYMENT_UNSUCCESSFUL:
    "There was an error processing your payment. Please try again or contact support@anansii.com",
  ERROR_ADMIN_DISTRICTMGR_UPDATE_FAILED:
    "Sorry, there was a problem this rep's district manager, please try again. If problem persists, contact support@anansii.com",

  ERROR_NO_INVOICES_SELECTED:
    "Oops! Please select invoices that you'd like to pay and then continue.",
  ERROR_PAST_PAYMENT_DATE:
    "Payment date is in the past. Please change the payment date to today or a date in the future.",
  ERROR_PAYMENT_REQUEST_FAILED:
    "There was an issue processing your request, please try again. If this problem persists, please contact support@anansii.com",

  FUNDBOX_CONSENT_HEADER: "You understand by clicking 'See Available Offers':",
  FUNDBOX_CONSENT_BODY:
    "You are providing your written instructions to Anansii and Fundbox to obtain your personal credit profile and business credit profile on your behalf from a credit reporting agency. You authorize Anansii and Fundbox to obtain such information to consider your business for financing options offered by Fundbox. Checking for offers does not impact your credit score.",
  FUNDBOX_APPLICATION_SUBMISSION_FAILED:
    "There was an issue submitting your application. Our team will review and respond to you soon.",
};

export default Strings;
